const statData = {
    "countries": {
        "russia": "Russland",
        "greatBritian": "Großbritannien",
        "france": "Frankreich",
        "germany": "Deutschland",
        "austriaHungary": "Österreich-Ungarn"
    },
    "data": [
        {
            "id": "population",
            "type": "base",
            "name": "Bevölkerung, 1910-1911",
            "russia": 160700000,
            "greatBritian": 40460000,
            "france": 39192000,
            "germany": 64296000,
            "austriaHungary": 51356000
        },

        {
            "id": "malePopulation",
            "type": "depending",
            "dependsOn": [
                "population"
            ],
            "name": "männliche Bevölkerung",
            "russia": 78790000,
            "greatBritian": 19638000,
            "france": 19254000,
            "germany": 32040000,
            "austriaHungary": 23374000
        },
        {
            "id": "yourClass",
            "type": "base",
            "name": "Jungen in eurer Klasse",
            "value": 30
        },
        {
            "id": "mobilizedPopulation",
            "type": "depending",
            "dependsOn": [
                "malePopulation"
            ],
            "name": "mobilisierte Männer 1914-1918",
            "russia": 13700000,
            "greatBritian": 6211000,
            "france": 8660000,
            "germany": 13250000,
            "austriaHungary": 8000000
        },
        {
            "id": "militaryLosses",
            "type": "depending",
            "dependsOn": [
                "mobilizedPopulation",
                "malePopulation"
            ],
            "name": "militärische Verluste, insgesamt",
            "russia": 5409000,
            "greatBritian": 2438000,
            "france": 3120000,
            "germany": 6193000,
            "austriaHungary": 6400000
        },
        {
            "id": "killedInAction",
            "type": "depending",
            "dependsOn": [
                "mobilizedPopulation",
                "malePopulation"
            ],
            "name": "gefallen",
            "russia": 1660000,
            "greatBritian": 745000,
            "france": 1420000,
            "germany": 2045000,
            "austriaHungary": 1100000
        },
        {
            "id": "wounded",
            "type": "depending",
            "dependsOn": [
                "mobilizedPopulation",
                "malePopulation"
            ],
            "name": "verwundet",
            "russia": 3749000,
            "greatBritian": 1693000,
            "france": 1700000,
            "germany": 4148000,
            "austriaHungary": 5300000
        }
    ]
};
export default statData;