const LOCALE = "de-DE";

function getByKey(array, key, val) {
    let item = null;
    array.forEach(function (v) {
        if (v[key] === val) {
            console.log(v);
            item = v;
        }
    });
    return item;
}

function getProportion(top, bottom) {
    return (top / bottom * 100).toFixed(0);
}

function calcWithProp(t, b) {
    return (t * (b / 100)).toFixed(0);
}

function isNumber(val) {
    return !Number.isNaN(val) && val !==""
}

export {LOCALE, getProportion, getByKey, calcWithProp, isNumber};