import React, {Component} from 'react';
import './App.css';
import {
    Col,
    Collapse,
    FormGroup,
    Input,
    Label,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink, Row
} from "reactstrap";
import statData from "./../data";

// Font awesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {faArrowDown, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import {ColumnChart} from "react-chartkick";
import DataTable from "./DataTable";
import {isNumber} from "../helper";

library.add(faArrowDown);
library.add(faArrowRight);


class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            maleStudents: 30,
        }
    }

    _onChangeMaleStudents = (e) => {
        const val = e.target.value;
        console.log(val);
        if (isNumber(val)) {
            this.setState({
                maleStudents: Number.parseInt(val)
            })
        } else {
            console.log("No number");
        }
    };

    render() {
        return (
            <div className="App">
                <header>
                    <Navbar color="light" light expand="md">
                        <NavbarBrand href="/">Militärische Mobilisierung und Verluste im 1. Weltkrieg
                            (1914–1918)</NavbarBrand>
                        <NavbarToggler onClick={this.toggle}/>
                        <Collapse isOpen={true} navbar>
                            <Nav className="ml-auto" navbar>
                                <NavItem>
                                    <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </header>
                <main>
                    <FormGroup>
                        <Label for="studentsCount">In eurer Klasse sind ... Jungen</Label>
                        <Input type="number" name="studentsCount" id="studentsCount"
                               placeholder="Anzahl der Jungen in eurer Klasse" onChange={this._onChangeMaleStudents}/>
                    </FormGroup>
                    <Row>
                        <Col>
                            <DataTable maleStudents={this.state.maleStudents}/>
                        </Col>


                        <Col>
                            <ColumnChart data={
                                [
                                    {
                                        "name": "mobilisierte Männer",
                                        "data": Object.keys(statData.countries).map(function (country) {
                                            const fullCountry = statData.countries[country];
                                            const mobilized = statData.data[3][country];
                                            return [fullCountry, mobilized];
                                        })
                                    },
                                    {
                                        "name": "miltitärische Verluste, insgesamt",
                                        "data": Object.keys(statData.countries).map(function (country) {
                                            const fullCountry = statData.countries[country];
                                            const militaryLosses = statData.data[4][country];
                                            return [fullCountry, militaryLosses];
                                        })
                                    }
                                ]
                            } ytitle={"Anzahl der Männer"} xtitle={"Land"}/>
                        </Col>
                    </Row>
                    <p className={"text-muted"}>
                        Quelle: Roger Chickering: Das Deutsche Reich und der Erste Weltkrieg. München 2002, S. 235
                    </p>
                </main>
                <footer>
                    <p>© 2018 by Jonathan Weth (<a href={"mailto:vis1ww@jonathanweth.de"}>vis1ww@jonathanweth.de</a>)</p>
                </footer>
            </div>
        );
    }
}

export default App;
