import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
    Table
} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import statData from "../data";
import {LOCALE, getByKey, getProportion, calcWithProp} from "../helper";

class NumberTd extends Component{
    render() {
        return <td className={"number"}><strong>{this.props.number.toLocaleString(LOCALE)}</strong> {this.props.legend}</td>;
    }
}

NumberTd.propTypes = {
    number: PropTypes.number.isRequired,
    legend: PropTypes.string
};

NumberTd.default = {
    legend: ""
};

export default class DataTable extends Component {
    render () {
        const t = this;

        return <Table>
            <thead>
            <tr>
                <th>Kategorie <FontAwesomeIcon icon={"arrow-down"}/> Land <FontAwesomeIcon
                    icon={"arrow-right"}/></th>
                {Object.values(statData.countries).map(function (country) {
                    return <th key={country}>{country}</th>
                })}
            </tr>
            </thead>
            {statData.data.map(function (item) {
                return <tbody key={item.id}>
                <tr>
                    <th scope={"row"}>{item.name}</th>
                    {Object.keys(statData.countries).map(function (country) {
                        let val = item.id !== "yourClass" ? item[country] : item.value;
                        val = Number(val);
                        return <NumberTd number={val} key={country}/>;
                    })}
                </tr>
                {item.type === "depending" ? item.dependsOn.map(function (depending, idx2) {
                    depending = getByKey(statData.data, "id", depending);
                    return <tr key={idx2}>

                        <td>Anteil an <em>{depending.name}</em></td>
                        {Object.keys(statData.countries).map(function (country) {
                            let val = item[country];
                            val = getProportion(val, depending[country]);
                            val = Number(val);
                            return <NumberTd number={val} legend={"%"} key={country}/>;
                        })}
                    </tr>;
                }) : null}

                {item.type === "depending" ? item.dependsOn.indexOf("malePopulation") !== -1 ? <tr>
                    <td>Umgerechnet auf eure Klasse</td>
                    {Object.keys(statData.countries).map(function (country) {
                        let val = item[country];
                        const depending = getByKey(statData.data, "id", "malePopulation");
                        val = getProportion(val, depending[country]);
                        val = Number(val);
                        let inClass = calcWithProp(t.props.maleStudents, val);
                        return <NumberTd number={inClass} legend={"Jungen"} key={country}/>;
                    })}
                </tr> : null : null}
                </tbody>;
            })}

        </Table>;
    }
}

DataTable.propTypes = {
    maleStudents: PropTypes.number
};